import type { Query } from "@directus/sdk";
import type { Branches, CustomDirectusTypes, DirectusUserMix } from "~/types/directus-collections";

type BranchesFields = Query<CustomDirectusTypes, Branches>["fields"];

export type FetchOptions = {
  fields: BranchesFields;
  status: "published" | "draft";
};

export async function fetchBranchBySlug(
  slug: string,
  { fields = ["*"], status = "published" }: Partial<FetchOptions> = {}
): Promise<Branches | null> {
  const results = await useDirectus<Branches[]>(
    readItems("branches", {
      fields,
      filter: {
        status: { _eq: status },
        slug: { _eq: slug },
      },
    })
  );
  return results?.[0] || null;
}

export function isBranchPresident(branch: Branches, user?: DirectusUserMix | null) {
  if (!user) user = useUser().value.user;
  const { branch_members: memberships } = user || {};
  return memberships?.some((el) => el.branch === branch.id && el.is_president === true);
}
